.h2, h2 {
    padding-top: 30px;
    display: block;
}

ol li a, ul li a{
    color: white;
    padding: 8px 0;
    display: block;
}
#ul, ul, ol {
    padding-left: 20px!important;
    display: block;
}

a {
    color: white
}
#for-script {
}

[main-attr] p, [main-attr] ul, [main-attr] ol {
    padding: 20px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
#specialUL li {
    padding: 10px 0!important;
}
#specialUL strong {
    display: block!important;
}

.pdfLinks a::after {
    content: 'Pdf файл';
    display: block;
    opacity: .6;
}

table {
    margin: 20px 0;
}
table td, table th {
    padding: 5px;
}